import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { Gray_Light_200, Gray_Light_400 } from '@common/styles';

export const LoadingDots = () => {
  return <Dots />;
};
const flashingAnimation = keyframes`
  0% {
    background-color: ${Gray_Light_400};
  }
  50%,
  100% {
    background-color: ${Gray_Light_200};
  }
`;

const Dots = styled.div`
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${Gray_Light_400};
  color: ${Gray_Light_400};
  animation: ${flashingAnimation} 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -9px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${Gray_Light_400};
    color: ${Gray_Light_400};
    animation: ${flashingAnimation} 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 9px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${Gray_Light_400};
    color: ${Gray_Light_400};
    animation: ${flashingAnimation} 1s infinite alternate;
    animation-delay: 1s;
  }
`;
