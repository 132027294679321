import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconChangeLight32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' strokeLinejoin='round' d='M11 24V9.5M21 8v14.5' />
      <path stroke='currentColor' d='M6.5 13.5 11 9l4.5 4.5M25.5 18.5 21 23l-4.5-4.5' />
    </Svg>
  );
};
export default SvgIconChangeLight32;
