export const GeneralCheckIcon = ({
  width = 16,
  height = 16,
  color = '#FF349C',
  ...props
}: JSX.IntrinsicElements['svg']) => (
  <svg width={width} height={height} color={color} viewBox='0 0 16 16' {...props}>
    <path
      d='M12.9075 3.5L14.3217 4.91421L6.43935 12.7966L1.40002 7.75729L2.81424 6.34308L6.43935 9.96819L12.9075 3.5Z'
      fill='currentColor'
    />
  </svg>
);
