export const SmallEmptyWarning24Icon = ({ width = 24, height = 24, ...props }: JSX.IntrinsicElements['svg']) => (
  <svg width={width} height={height} fill='none' viewBox='0 0 24 24' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.3187 2.28552L22.7462 21.4851H1.42847L14.3187 2.28552Z'
      fill='#E9ECEF'
    />
    <mask id='mask0_2002:1434' maskUnits='userSpaceOnUse' x='1' y='2' width='22' height='20'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3187 2.28552L22.7462 21.4851H1.42847L14.3187 2.28552Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_2002:1434)'></g>
    <mask id='mask1_2002:1434' maskUnits='userSpaceOnUse' x='11' y='9' width='4' height='7'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.4418 9.62927L13.7454 15.646H11.9724L12.6744 9.62927H14.4418Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask1_2002:1434)'>
      <path fillRule='evenodd' clipRule='evenodd' d='M6.97241 4.62927H19.4418V20.646H6.97241V4.62927Z' fill='#ADB5BD' />
    </g>
    <mask id='mask2_2002:1434' maskUnits='userSpaceOnUse' x='11' y='16' width='3' height='3'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.601 16.8926L13.3747 18.8473H11.5991L11.8271 16.8926H13.601Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask2_2002:1434)'>
      <path fillRule='evenodd' clipRule='evenodd' d='M6.59912 11.8926H18.601V23.8473H6.59912V11.8926Z' fill='#ADB5BD' />
    </g>
  </svg>
);
