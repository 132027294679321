import { FC, useMemo, useState } from 'react';
import { IconChangeLight32 } from '@croquiscom/zds';
import styled from '@emotion/styled';

import { GeneralCheckIcon } from '@common/icons';
import {
  Gray_Light_100,
  Gray_Light_150,
  Gray_Light_700,
  Gray_Light_900,
  Gray_Light_Black,
  Typography,
} from '@common/styles';
import { BottomSheet, SortingItemListType } from '@domains/storehome-common';

interface Porps {
  sort_item_list: SortingItemListType[];
  onChange: (id: string) => void;
}

export const SortButton: FC<Porps> = ({ sort_item_list, onChange }) => {
  const [showBottomSheet, setShow] = useState(false);
  const selectedItem = useMemo(() => sort_item_list.find((item) => item.selected), [sort_item_list]);

  return (
    <Root>
      <Button onClick={() => setShow(true)}>
        <IconChangeLight32 color={Gray_Light_700} size={20} />
        <Text className={Typography.BODY_13_SEMIBOLD}>{selectedItem?.name}</Text>
      </Button>

      {showBottomSheet && (
        <BottomSheet isActive={showBottomSheet} setIsActive={setShow}>
          <BottomSheetHandle />
          <SortList>
            {sort_item_list.map(({ id, name, selected }, index) => (
              <SortItem
                className={Typography.BODY_16_SEMIBOLD}
                key={`${id}_${index}`}
                onClick={() => {
                  onChange(id);
                  setShow(false);
                }}
              >
                <span>{name}</span>
                {selected && <GeneralCheckIcon color={Gray_Light_Black} />}
              </SortItem>
            ))}
          </SortList>
        </BottomSheet>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  padding: 5px;
`;

const Button = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const Text = styled.span`
  color: ${Gray_Light_700};
`;

const BottomSheetHandle = styled.div`
  position: absolute;
  background-color: ${Gray_Light_150};
  border-radius: 2px;
  top: 6px;
  left: calc(50% - 20px);
  width: 40px;
  height: 4px;

  &::before {
    content: '';
    background-color: ${Gray_Light_150};
    border-radius: 2px;
    width: 40px;
    height: 4px;
  }
`;

const SortList = styled.ul`
  padding: 0 16px 0;

  li {
    &:last-of-type {
      border: none;
    }
  }
`;

const SortItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Gray_Light_100};
  box-sizing: border-box;
  height: 56px;
  color: ${Gray_Light_900};
  cursor: pointer;

  svg {
    margin-left: auto;
  }
`;
