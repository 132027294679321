/* eslint react-hooks/rules-of-hooks: 'warn' */

import { FC, useState } from 'react';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';

import { Gray_Light_200, Gray_Light_500, Gray_Light_700, Gray_Light_900, Typography } from '@common/styles';
import { CheckBox } from '@common/toolbox';
import { TextAndSortingComponentType } from '@domains/storehome-common';

import { ComponentProps } from '../../types';
import { SortButton } from './sort-button';

interface Props extends ComponentProps<Omit<TextAndSortingComponentType, 'type'>> {
  padding?: string;
  filter_height?: string;
  onChangeFilter: (params: { check_button_item_ids: string[]; sorting_item_id: string }) => void;
}

export const Filter: FC<Props> = ({
  component: sort_component,
  padding = '0 16px',
  filter_height = '48px',
  onChangeFilter,
}) => {
  if (!sort_component) {
    return null;
  }

  const { main_title, sub_title, check_button_item_list = [], sorting_item_list = [] } = sort_component;
  const [check_button_ids, setCheckButtonId] = useState(() =>
    check_button_item_list
      .filter((check_button_item) => check_button_item.selected)
      .map((check_button_item) => check_button_item.id),
  );
  const [sorting_list, setSortingList] = useState(sorting_item_list);

  const changeCheckBoxItem = (id: string, checked: boolean) => {
    setCheckButtonId((prev) => {
      const new_check_button_ids = checked ? [...prev, id] : prev.filter((p) => p !== id);
      const sorting_item_id = sorting_list.filter((item) => item.selected)[0]?.id || '';

      onChangeFilter({
        check_button_item_ids: new_check_button_ids,
        sorting_item_id,
      });

      return new_check_button_ids;
    });
  };

  const changeSortItem = (id: string) => {
    setSortingList((prev) => {
      onChangeFilter({
        check_button_item_ids: check_button_ids,
        sorting_item_id: id,
      });

      return prev.map((data) => ({
        ...data,
        selected: data.id === id,
      }));
    });
  };

  return (
    <Wrap className='filter' padding={padding}>
      {main_title?.text && <MainTitle className={Typography.BODY_17_SEMIBOLD}>{main_title.text}</MainTitle>}
      <FilterWrap height={filter_height}>
        {sub_title?.text && <SubTitle className={Typography.CAPTION_11_BOLD}>{sub_title.text}</SubTitle>}
        <ShoppingGroup>
          {check_button_item_list.map(({ id, name, image_url, disabled }, index) => (
            <ShoppingItem key={`${id}-${index}`}>
              <ShoppingCheckBox
                checked={check_button_ids.includes(id)}
                disabled={disabled || false}
                defaultValue={id}
                checkEvent={(checked) => {
                  changeCheckBoxItem(id, checked);
                }}
              >
                <div className={cx('checkbox-text', Typography.BODY_13_SEMIBOLD)}>
                  {image_url ? <img src={image_url} alt={name ?? ''} /> : name}
                </div>
              </ShoppingCheckBox>
            </ShoppingItem>
          ))}
        </ShoppingGroup>

        <SortButtonWrap>
          {sorting_list.length > 0 && <SortButton sort_item_list={sorting_list} onChange={changeSortItem} />}
        </SortButtonWrap>
      </FilterWrap>
    </Wrap>
  );
};

const Wrap = styled.section<{ padding: string }>`
  position: relative;
  padding: ${({ padding }) => padding};
`;

const MainTitle = styled.p`
  padding: 19px 0 4px 0;
  height: 46px;
  letter-spacing: -0.3px;
  color: ${Gray_Light_900};
`;

const SubTitle = styled.span`
  color: ${Gray_Light_500};
`;

const FilterWrap = styled.div<{ height: string }>`
  display: flex;
  align-items: center;
  height: ${({ height }) => height};
`;

const ShoppingGroup = styled.ul`
  display: flex;
  align-items: center;
`;

const ShoppingItem = styled.li`
  margin: 0 12px 0 0;
`;

const ShoppingCheckBox = styled(CheckBox)<{ checked: boolean; disabled: boolean }>`
  display: flex;

  input,
  svg {
    width: 16px;
    height: 16px;
  }

  .checkbox-text {
    margin: 0 0 0 4px;
    color: ${({ disabled }) => (disabled ? Gray_Light_200 : Gray_Light_700)};

    & > img {
      width: 42px;
      height: auto;
    }
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const SortButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
