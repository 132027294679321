import { FC } from 'react';
import styled from '@emotion/styled';

import { SmallEmptyWarning24Icon } from '@common/icons';
import { Gray_Light_300, Typography } from '@common/styles';

interface Props {
  message: string;
}

export const DefaultError: FC<Props> = ({ message }) => {
  return (
    <Wrap>
      <SmallEmptyWarning24Icon />
      <ErrorMessage className={Typography.BODY_15_MEDIUM}>{message}</ErrorMessage>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ErrorMessage = styled.p`
  margin: 16px 0 0 0;
  color: ${Gray_Light_300};
  white-space: pre;
  text-align: center;
`;
