import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Gray_Light_100, Gray_Light_900, web_max_width } from '@common/styles';

interface BottomSheetProps {
  title?: string;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  disableBackdropClick?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  children,
  title,
  isActive,
  showCloseButton,
  disableBackdropClick,
  setIsActive,
  onClose = () => undefined,
}) => {
  const bottomSheetRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const { t: tc } = useTranslation('common');

  useEffect(() => {
    if (isActive) {
      setTimeout(() => setIsVisible(true), 0);
    }
  }, [isActive]);

  useEffect(() => {
    if (!bottomSheetRef || !bottomSheetRef.current) {
      return;
    }

    const transitionEndCallback = () => {
      setIsActive(isActive);
      if (!isVisible) {
        setIsActive(false);
      }
    };
    bottomSheetRef.current.addEventListener('transitionend', transitionEndCallback);

    return () => {
      if (!bottomSheetRef || !bottomSheetRef.current) {
        return;
      }
      bottomSheetRef.current.removeEventListener('transitionend', transitionEndCallback);
    };
  }, [isVisible, bottomSheetRef.current]);

  const handleCloseButtonClick = () => {
    setIsVisible(false);
    onClose();
  };

  const handleOutsideClick = () => {
    if (disableBackdropClick === true) {
      return;
    }
    handleCloseButtonClick();
  };
  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (event) => event.stopPropagation();

  return ReactDOM.createPortal(
    <Wrapper isVisible={isVisible} onClick={handleOutsideClick} ref={bottomSheetRef}>
      <Container isVisible={isVisible} onClick={stopPropagation}>
        {title && <Title>{title}</Title>}
        {children}
        {showCloseButton && (
          <CloseButtonWrapper>
            <CloseButton type='button' onClick={handleCloseButtonClick}>
              {tc('확인')}
            </CloseButton>
          </CloseButtonWrapper>
        )}
      </Container>
    </Wrapper>,
    document.body,
  );
};

const Wrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: ${web_max_width}px;
  height: 100%;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  transition: background 0.2s;
  z-index: 9998;
  ${({ isVisible }) => isVisible && `background: rgba(73, 80, 87, 0.7);`}
`;

const Container = styled.div<{ isVisible: boolean }>`
  position: absolute;
  z-index: 9999;
  margin: 0;
  padding: 16px 0 0;
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
  width: 100%;
  background: #fff;
  left: 0;
  bottom: 0;
  border-radius: 8px 8px 0px 0px;
  transform: translateY(100%);
  transition: transform 0.5s;
  ${({ isVisible }) => isVisible && `transform: translateY(0);`}
`;

const Title = styled.div`
  margin: 0;
  padding: 0;
  height: 44px;
  line-height: 44px;
  font-family: Apple SD Gothic Neo;
  font-weight: 800;
  font-size: 17px;
  text-align: center;
  letter-spacing: -0.3px;
  color: ${Gray_Light_900};
`;

const CloseButtonWrapper = styled.div`
  padding: 0 24px 24px;
`;

const CloseButton = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 48px;
  line-height: 48px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: ${Gray_Light_900};
  background: ${Gray_Light_100};
`;
